<template>
    <!-- 路内停车场 ————> 查看一体机 -->
    <div class="box-card" style="text-align: left;margin-top: 0;">
      <!-- 主要内容 -->
      <div class="content">
        <el-form label-position="right" label-width="120px" :model="details" class="left">
          <!-- <div class="title">基本信息</div>
          <el-divider></el-divider> -->
          <el-form-item label="设备名称：">{{details.integrated_machine_name}}</el-form-item>
          <el-form-item label="设备品牌：">{{details.integrated_machine_brand_name}}</el-form-item>
          <el-form-item label="设备型号：">{{details.integrated_machine_model_name}}</el-form-item>
          <!-- <div class="title" style="margin-top:100px;">硬件属性</div>
          <el-divider></el-divider> -->
          <el-form-item label="序列号：">{{details.integrated_machine_serial}}</el-form-item>
          <!-- <el-form-item label="硬件版本：">{{details.hardware_version}}</el-form-item>
          <el-form-item label="软件版本：">{{details.software_version}}</el-form-item> -->
          <el-form-item label="工作模式：">
            <div :style="details.work_model == 2 ? 'color: #ff0000;' : 'color: #18943b;'">{{details.work_model == 2 ? "异常" : "正常" }}</div>
          </el-form-item>
          <el-form-item label="设备状态：">
            <div :style="details.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">{{details.device_state == 2 ? "离线" : "在线" }}</div>
          </el-form-item>
          <el-form-item label="状态更新时间：">
            {{ $moment(details.heartbeat_time).format("YYYY-MM-DD HH:mm:ss") }}
          </el-form-item>
        </el-form>
        <div class="right">
          <el-image :src="details.integrated_machine_model_pic" v-if="details.integrated_machine_model_pic">
          </el-image>
          <div class="innerText">设备图片</div>
        </div>
      </div>
    </div>
  </template>
<script>
import { getIntegratedMachineDetails } from '@/api/deviceApi'
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      details: {}
    }
  },
  created () {
    this.fnGetIntegratedMachineDetails()
  },
  mounted () { },
  computed: {
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fnGetIntegratedMachineDetails () {
      const res = await getIntegratedMachineDetails({
        camera_id: this.id
      })
      this.details = res && res.Code === 200 ? res.Data : {}
      console.log(res)
    },
    // 返回一体机页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>

  <style scoped lang="less">
  .content {
    width: 100%;
    padding:0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .content .left {
    width: 70%;
  }
  .left .el-form-item__content {
    text-align: left;
  }
  .left .el-row {
    width: 100%;
  }
  .left .el-form-item {
    margin-bottom: 0px;
  }
  .content .right {
    max-height: 650px;
    overflow: hidden;
    text-align: center;
    width: 40%;
    background-color: #FAFAFA;
    .innerText{
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #7D7D7D;
    }
    /deep/ .el-image {
      width: 100%;
      max-height: 650px;
      text-align: center;
      .el-image__inner {
        // max-width: inherit;
        max-height: inherit;
        width: fit-content;
      }
    }
  }
  </style>
