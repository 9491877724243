<template>
  <div class="box-card">
    <!-- 一体机 -->
    <div>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：" v-if="this.getUserInfo.user_name === 'admin'">
          <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in getAllParkingList(2)" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：">
          <el-input v-model="queryForm.device_serial" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input v-model="queryForm.device_name" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" placeholder="请选择设备状态" clearable v-model="queryForm.device_state"
            @change="onSubmit" @clear="queryForm.device_state = null">
            <el-option label="在线" :value="1"></el-option>
            <el-option label="离线" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="支付一体机">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('160dd574227d4147a5b5b164') ||
              isShowBtn('08141e707ec64a12804ae5d4')
              ">删除</lbButton>
            <lbButton icon="xinzeng" @click="openAddForm()" v-if="this.getUserInfo.user_name != 'admin' &&
              (isShowBtn('ac267cf1e15f44eca29da5f8') ||
                isShowBtn('6c252d0f245b42b39bd551b6'))
              ">新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div style="text-align: left">
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="device_state">
              <div :style="row.device_state == 2
                    ? 'color: #ff0000;'
                    : 'color: #18943b;'
                  ">
                  {{ row.device_state == 2 ? "离线" : "在线" }}
                </div>
            </template>
            <template slot-scope="{row}" slot="parking_name">
              <div :style="row.parking_name != '未配置' ? '' : 'color: #aaa;'
                  ">
                  {{ row.parking_name }}
                </div>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookOnenessMachine(row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)" v-if="isShowBtn('5efbe84f371d4577883ea3e2') ||
                  isShowBtn('ec9169bf67ab4a55bd4c333d')
                  "></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="integrated_machine_name" label="设备名称"></el-table-column>
            <el-table-column prop="integrated_machine_brand_name" label="设备品牌"></el-table-column>
            <el-table-column prop="integrated_machine_model_name" label="设备型号"></el-table-column>
            <el-table-column prop="ipaddr" label="IP"></el-table-column>
            <el-table-column label="状态" width="80">
              <template slot-scope="scope">
                <div :style="scope.row.device_state == 2
                    ? 'color: #ff0000;'
                    : 'color: #18943b;'
                  ">
                  {{ scope.row.device_state == 2 ? "离线" : "在线" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="停车场" v-if="this.getUserInfo.user_name === 'admin'">
              <template slot-scope="scope">
                <div :style="scope.row.parking_name != '未配置' ? '' : 'color: #aaa;'
                  ">
                  {{ scope.row.parking_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="230">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookOnenessMachine(scope.row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if="isShowBtn('5efbe84f371d4577883ea3e2') ||
                  isShowBtn('ec9169bf67ab4a55bd4c333d')
                  "></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total" >
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增对话框 -->
    <lebo-dialog append-to-body :title=" addForm._id?'编辑设备':'新增设备'" :isShow="isAddDialog" width="35%" footerSlot
      @close="closeAddDialogVisible()">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <el-form-item label="设备名称：" prop="integrated_machine_name">
          <el-input v-model="addForm.integrated_machine_name" placeholder="请输入设备名称" maxlength="30"
            @input="(e) => (addForm.integrated_machine_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="设备品牌：" prop="integrated_machine_brand_name">
          <el-select popper-class="my-select" v-model="addForm.integrated_machine_brand_name" placeholder="请选择设备品牌"
            @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
              :value="item.brand_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号：" prop="integrated_machine_model_name">
          <el-select popper-class="my-select" v-model="addForm.integrated_machine_model_name" placeholder="请选择设备型号"
            @change="modelChange">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
              :value="item.model_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="IP地址：" prop="ipaddr">
          <el-input v-model="addForm.ipaddr" maxlength="30" placeholder="请输入IP地址" @input="(e) => (addForm.ipaddr = validFacility(e))
            "></el-input>
        </el-form-item>
        <el-form-item label="端口号：" prop="port">
          <el-input v-model="addForm.port" maxlength="30" placeholder="请输入端口号" @input="(e) => (addForm.port = validFacility(e))
            "></el-input>
        </el-form-item>
        <el-form-item label="序列号：">
          <el-input v-model="addForm.integrated_machine_serial" maxlength="30" placeholder="请输入序列号" @input="(e) => (addForm.integrated_machine_serial = validFacility(e))
            "></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看设备 -->
    <lebo-dialog :isShow="showlookOnenessMachine" width="50%" title="查看设备"
      :footerSlot="true" @close="showlookOnenessMachine = false" closeOnClickModal>
      <onenessMachine :id="dialogOnenessMachineId" v-if="showlookOnenessMachine"></onenessMachine>
    </lebo-dialog>
  </div>
</template>
<script>
import {
  getIntegratedMachineList,
  addIntegratedMachine,
  updateIntegratedMachine,
  delIntegratedMachine,
  getBrandList,
  getModelList
} from '@/api/deviceApi'
import { mapState, mapGetters, mapMutations } from 'vuex'
import onenessMachine from './components/lookOnenessMachine/index.vue'
export default {
  components: { onenessMachine },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: '',
        PageSize: 10,
        PageIndex: 1,
        device_name: '',
        device_serial: '',
        device_state: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 是否展示新增或编辑对话框
      isAddDialog: false,
      // 新增表单
      addForm: {
        _id: '',
        integrated_machine_brand_id: '', // 设备品牌id
        integrated_machine_brand_name: '', // 设备品牌名称
        integrated_machine_model_id: '', // 设备型号id
        integrated_machine_model_name: '', // 设备型号名称
        integrated_machine_model_pic: '', // 设备型号图片地址
        integrated_machine_name: '', // 设备名称
        integrated_machine_serial: '', // 设备序列号
        ipaddr: '',
        port: ''
      },
      // 新增表单验证
      addFormRules: {
        integrated_machine_name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        integrated_machine_brand_name: [{ required: true, message: '请选择设备品牌', trigger: 'blur' }],
        integrated_machine_model_name: [{ required: true, message: '请选择设备型号', trigger: 'blur' }],
        ipaddr: [{ required: true, message: '请输入IP地址', trigger: 'blur' }],
        port: [{ required: true, message: '请输入端口号', trigger: 'blur' }]
      },
      // 设备品牌下拉列
      brandList: [],
      // 设备型号下拉列
      modelList: [],
      dialogOnenessMachineId: '', // 设备id
      showlookOnenessMachine: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '设备名称',
            prop: 'integrated_machine_name'
          }, {
            label: '设备品牌',
            prop: 'integrated_machine_brand_name'
          }, {
            label: '设备型号',
            prop: 'integrated_machine_model_name'
          }, {
            label: 'IP',
            prop: 'ipaddr'
          }, {
            label: '状态',
            prop: 'device_state',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetIntegratedMachineList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus', 'parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getUserInfo', 'getAllParkingList'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetIntegratedMachineList () {
      const res = await getIntegratedMachineList({
        parking_id: this.queryForm.parking_id,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_name: this.queryForm.device_name,
        device_serial: this.queryForm.device_serial,
        device_state: this.queryForm.device_state
          ? this.queryForm.device_state
          : null
      })
      console.log(res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetIntegratedMachineList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      this.queryForm.PageSize = val
      this.fnGetIntegratedMachineList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetIntegratedMachineList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetIntegratedMachineList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetIntegratedMachineList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的一体机, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._ids = arr
                console.log(obj)
                this.fnDelIntegratedMachine(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的一体机！')
      }
    },
    async fnDelIntegratedMachine (ids) {
      const res = await delIntegratedMachine(ids)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetIntegratedMachineList()
    },
    // 打开新增设备对话框
    openAddForm () {
      this.addForm._id = ''
      this.addForm.integrated_machine_brand_id = ''
      this.addForm.integrated_machine_brand_name = ''
      this.addForm.integrated_machine_model_id = ''
      this.addForm.integrated_machine_model_name = ''
      this.addForm.integrated_machine_model_pic = ''
      this.addForm.integrated_machine_name = ''
      this.addForm.integrated_machine_serial = ''
      this.addForm.ipaddr = ''
      this.addForm.port = ''
      this.isAddDialog = true
      this.fnGetBrandList()
    },
    // 打开修改设备对话框
    openEditForm (row) {
      console.log(row)
      this.isAddDialog = true
      this.fnGetBrandList()
      this.fnGetModelList(row.integrated_machine_brand_id)
      var that = this
      this.$nextTick(() => {
        that.addForm._id = row._id
        that.addForm.integrated_machine_brand_id =
          row.integrated_machine_brand_id
        that.addForm.integrated_machine_brand_name =
          row.integrated_machine_brand_name
        that.addForm.integrated_machine_model_id =
          row.integrated_machine_model_id
        that.addForm.integrated_machine_model_name =
          row.integrated_machine_model_name
        that.addForm.integrated_machine_model_pic =
          row.integrated_machine_model_pic
        that.addForm.integrated_machine_name = row.integrated_machine_name
        that.addForm.integrated_machine_serial = row.integrated_machine_serial
        that.addForm.ipaddr = row.ipaddr
        that.addForm.port = row.port
      })
    },
    // 设备品牌下拉框
    async fnGetBrandList () {
      const res = await getBrandList({
        //  1:视频桩 2:地磁 3:高位相机 4:路牙机 5.车牌识别相机 6.显示屏 7.一体机
        device_type: 7
      })
      // console.log(res);
      this.brandList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备品牌下拉框发生改变时
    brandChange (val) {
      console.log(val)
      var index = this.brandList.findIndex((item) => item.brand_name === val)
      this.addForm.integrated_machine_brand_id = this.brandList[index]._id
      this.fnGetModelList(this.brandList[index]._id)
      this.addForm.integrated_machine_model_id = ''
      this.addForm.integrated_machine_model_name = ''
      this.addForm.integrated_machine_model_pic = ''
    },
    // 设备型号下拉框
    async fnGetModelList (id) {
      const res = await getModelList({
        brand_id: id,
        device_type: 7
      })
      // console.log(res);
      this.modelList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备型号下拉框发生改变时
    modelChange (val) {
      // console.log(val);
      var index = this.modelList.findIndex((item) => item.model_name === val)
      this.addForm.integrated_machine_model_id = this.modelList[index]._id
      this.addForm.integrated_machine_model_pic = this.modelList[index].pic
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      this.isAddDialog = false
      // 移除校验结果
      this.$refs.addFormRef.resetFields()
      this.addForm.integrated_machine_brand_id = ''
      this.addForm.integrated_machine_model_id = ''
      this.addForm.integrated_machine_model_pic = ''
      this.addForm._id = ''
      this.addForm.integrated_machine_serial = ''
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          // var obj = JSON.parse(JSON.stringify(this.addForm))
          var obj = {
            integrated_machine_brand_id:
              this.addForm.integrated_machine_brand_id,
            integrated_machine_brand_name:
              this.addForm.integrated_machine_brand_name,
            integrated_machine_model_id:
              this.addForm.integrated_machine_model_id,
            integrated_machine_model_name:
              this.addForm.integrated_machine_model_name,
            integrated_machine_model_pic:
              this.addForm.integrated_machine_model_pic,
            integrated_machine_name: this.addForm.integrated_machine_name,
            integrated_machine_serial: this.addForm.integrated_machine_serial,
            ipaddr: this.addForm.ipaddr,
            port: this.addForm.port
          }
          if (this.addForm._id) {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fnUpdateIntegratedMachine(obj)
          } else {
            this.fnAddIntegratedMachine(obj)
          }
        } else {
          return false
        }
      })
    },
    async fnAddIntegratedMachine (obj) {
      const res = await addIntegratedMachine(obj)
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.onSubmit()
      }
    },
    async fnUpdateIntegratedMachine (obj) {
      const res = await updateIntegratedMachine(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.fnGetIntegratedMachineList()
      }
    },
    lookOnenessMachine (id) {
      this.showlookOnenessMachine = true
      this.dialogOnenessMachineId = id
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}
</style>
